@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

h2 {
  border: 1px solid black;
  border-radius: 5px;
}

html{
  background-color: #00061a;
  height: 100%;
  width: 100%;
  padding-top: 0;
}

.navbar {
  border: 5px solid black;
}

.signup-btn{
  background-color: #8B4000;
  border-radius: 10px;
  border: 1px solid black;
  height: 100%;
}

.load-progress-btn{
  background-color: #8B4000;
  border-radius: 10px;
  border: 1px solid black;
  height: 100%;
}

.signup-quest-btn{
  background-color: #8B4000;
  border-radius: 10px;
  border: 1px solid black;
  height: 100%;
}

.login-quest-btn{
  background-color: #00061a;
  border-radius: 10px;
  background-color: transparent;
  height: 100%;
}

.save-btn{
  background-color: #8B4000;
  border-radius: 10px;
  border: 1px solid black;
  height: 100%;
}

.saving-btn{
  background-color: #8B4000;
  border-radius: 10px;
  border: 1px solid black;
  height: 100%;
}

.restart-btn{
  background-color: #8B4000;
  border-radius: 10px;
  border: 1px solid black;
  height: 100%;
}

.navdropdown{
  background-color: black;
}

.list-group-item{
  background-color: black;	
}

.container-fluid {
  background-color: #00061a;
  height: 100%;
  width: 100%;
  padding-top: 0;
  border-color: green;
}

.img-fluid {
    padding-top: 0;
}

.message-animation {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  font-size: 1.5em;
  animation: typing 5s steps(60, end);
  position: relative;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes cursor-blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.message-animation::before {
  content: "> "; 
}

.message-animation::after {
  content: '▮'; 
  display: inline-block;
  opacity: 1;
  font-size: 1.5em;
  animation: typing 5s steps(60, end), cursor-blink 0.75s infinite;
}

.list-group-item:hover {
  color: black !important; 
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .text-md {
    font-size: 0.9em;
  }
}

@media (max-width: 767.98px) {
  .text-sm {
    font-size: 0.8em; 
  }
}

@media (max-width: 575.98px) {
  .text-xs-sm {
    font-size: 0.4em; 
}
}
